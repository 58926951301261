<!-- src/components/Redoc.vue -->
<template>
  <!-- This container will host the Redoc UI -->
  <div ref="redocContainer"></div>
</template>

<script>
// Optional: a module-level variable to avoid loading the script multiple times.
let redocScriptLoadingPromise = null;

export default {
  name: 'Redoc',
  props: {
    /**
     * The URL to your OpenAPI/Swagger spec (JSON or YAML).
     */
    specUrl: {
      type: String,
      required: true
    },
    /**
     * (Optional) Additional options to pass to Redoc.
     * See the [Redoc options documentation](https://github.com/Redocly/redoc#redoc-options-object) for available options.
     */
    options: {
      type: Object,
      default: () => ({})
    }
  },
  mounted() {
    this.loadRedocScript()
      .then(() => {
        this.initRedoc();
      })
      .catch((error) => {
        console.error('Failed to load Redoc script:', error);
      });
  },
  methods: {
    /**
     * Dynamically load the Redoc script if it hasn't been loaded already.
     * Uses a module-level promise to ensure the script is only loaded once.
     */
    loadRedocScript() {
      // If Redoc is already available, return an immediately resolved promise.
      if (window.Redoc) {
        return Promise.resolve();
      }

      // If we're already loading the script, return the existing promise.
      if (redocScriptLoadingPromise) {
        return redocScriptLoadingPromise;
      }

      // Otherwise, create a new promise to load the script.
      redocScriptLoadingPromise = new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = 'https://cdn.jsdelivr.net/npm/redoc/bundles/redoc.standalone.js';
        script.async = true;
        script.onload = () => {
          resolve();
        };
        script.onerror = () => {
          reject(new Error('Failed to load the Redoc script.'));
        };
        document.head.appendChild(script);
      });

      return redocScriptLoadingPromise;
    },

    /**
     * Initialize Redoc by calling its global init method.
     */
    initRedoc() {
      if (window.Redoc && this.$refs.redocContainer) {
        window.Redoc.init(this.specUrl, this.options, this.$refs.redocContainer);
      } else {
        console.error('Redoc is not available or the container is missing.');
      }
    }
  },
  watch: {
    // If the spec URL changes, re-initialize Redoc.
    specUrl(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.initRedoc();
      }
    }
  }
};
</script>

<style scoped>
/* Add any styles you need for your Redoc container */
</style>
