<!-- src/App.vue -->
<template>
  <div id="app">
    <RedocComponent 
      v-if="url" :specUrl="url" 
      :options="{ scrollYOffset: 50 }" 
    />
  </div>
</template>

<script>
import RedocComponent from '@/view/components/RedocComponent.vue';
import { get_domain_url, get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import axios from 'axios';

export default {
  name: 'App',
  components: {
    RedocComponent
  },
  mounted() {
    axios 
      .get('/page/api_doc_token')
      .then(res => {

        let url = get_base_url();

        if (url.indexOf('localhost') >= 0) {
          this.url = `http://localhost:3020/swagger-doc-${res.data.api_doc_token}`;
        }
        else {
          this.url = `${url}/swagger-doc-${res.data.api_doc_token}`;
        }
        
      })
      .catch(err => {
        console.error(err);
      });
  },
  data() {
    return {
      url: null,
      // https://github.com/Redocly/redoc#redoc-options-object
      redocOptions: {
        hideDownloadButton: false      
      }
    }
  }
};
</script>
